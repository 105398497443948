//  Modules from the React eco-system
import { useState, useEffect } from "react"

// Own components
import SubPageNavbar from "../components/navigation/subpage-navbar.component"
import Loader from "../components/loader.component"

// Navigation
import { Switch, Route, useHistory } from "react-router-dom"
import adminMainRoutes from "../routes/mains/administartors-main.route"

// Axios
import axiosClient from "../api/api"

// Redux
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"

// Interfaces, enums
import { ISuperAdmin } from "../interfaces/persons"
import { ISubPageRoute } from "../interfaces/pages"

function SuperadminPage() {
    const dispatch = useDispatch()
    const history = useHistory()

    const [superadmins, setSuperadmins] = useState<ISuperAdmin[]>([])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        dispatch(setPageName({ pageName: "Administrators", pageUrl: "/admin/superadmins", subPageName: "" }))

        fetchSuperadmins()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchSuperadmins() {
        setIsLoading(true)
        try {
            const response = await axiosClient.get("/superadmin")
            setSuperadmins(response.data)
        } catch (error) {
            console.log("Failed to get superadmins")
            history.push("/admin/404")
        }
        setIsLoading(false)
    }

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {superadmins.length && (
                        <div>
                            <SubPageNavbar routes={adminMainRoutes} />

                            <Switch>
                                {adminMainRoutes.map((route: ISubPageRoute, key: number) => {
                                    return (
                                        <Route
                                            key={key}
                                            path={route.path}
                                            exact
                                            render={() => {
                                                return (
                                                    <div>
                                                        <route.component admins={superadmins} />
                                                    </div>
                                                )
                                            }}
                                        />
                                    )
                                })}
                            </Switch>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default SuperadminPage
