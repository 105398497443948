// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"
import { homeAssistantUrl } from "../../config/config"

/**
 * Display data of sensors of one cube
 */
function CubeSensors() {
    return (
        <div className="cube-sensors">
            <Row>
                <Col>
                    <iframe title="home-assistant" className="home-assistant" src={homeAssistantUrl}></iframe>
                </Col>
            </Row>
        </div>
    )
}

export default CubeSensors
