import React from "react"
import { Chart as ChartJS, ChartData, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip } from "chart.js"
import { Bar } from "react-chartjs-2"

ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip)

const options = {
    maintainAspectRatio: false,
    scales: {
        y: {
            beginAtZero: true,
        },
    },
}

interface IProps {
    data: ChartData<any>
}

function ChartEvents(props: IProps) {
    return <Bar options={options} data={props.data} />
}

export default ChartEvents
