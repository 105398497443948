//  Modules from the React eco-system
import { useState, useEffect, useContext } from "react"
import { useParams, Route, Switch } from "react-router"
import { useHistory } from "react-router-dom"

// Own components
import SubPageNavbar from "../components/navigation/subpage-navbar.component"

// Axios
import axiosClient from "../api/api"

// Redux, store
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"
import { UserContext } from "../store/UserProvider"

// Navigation
import userDetailsRoutes from "../routes/details/user-details.route"

// Interfaces, enums
import { IUser } from "../interfaces/persons"
import Loader from "../components/loader.component"
import { ERoles } from "../constants/enum"
import { ISubPageRoute } from "../interfaces/pages"

interface RouteParams {
    id: string
}

/**
 * Page of one user
 * @returns
 */
function UserDetails() {
    //Logged in user
    const { user } = useContext(UserContext)

    const dispatch = useDispatch()
    const params = useParams<RouteParams>()
    const history = useHistory()

    // User from the fetch
    const [bbUser, setUser] = useState<IUser | null>(null)

    useEffect(() => {
        // fetch user
        fetchUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // set page name
        dispatch(setPageName({ pageName: "Users", pageUrl: "/admin/users/main", subPageName: bbUser ? `${bbUser.firstname} ${bbUser.lastname}` : "" }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bbUser])

    /**
     * fetch user from the database
     */
    async function fetchUser() {
        try {
            if (params && params.id) {
                const response = await axiosClient.get(`/user/${params.id}`)
                setUser(response.data)
            }
        } catch (error) {
            console.log("Failed to fetch user")
            history.push("/admin/404")
        }
    }

    return (
        <div>
            {bbUser ? (
                <div>
                    <SubPageNavbar
                        routes={userDetailsRoutes.filter((route: ISubPageRoute) => {
                            return route.permission?.includes(user.role as ERoles)
                        })}
                        id={bbUser._id}
                    />

                    <Switch>
                        {userDetailsRoutes
                            .filter((route: ISubPageRoute) => {
                                return route.permission?.includes(user.role as ERoles)
                            })
                            .map((route: any, key: number) => {
                                return (
                                    <Route
                                        key={key}
                                        path={route.path}
                                        exact
                                        render={() => {
                                            return (
                                                <div>
                                                    <route.component user={bbUser} setUser={setUser} />
                                                </div>
                                            )
                                        }}
                                    />
                                )
                            })}
                    </Switch>
                </div>
            ) : (
                <Loader />
            )}
        </div>
    )
}

export default UserDetails
