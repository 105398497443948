import moment from "moment"
import React, { useState, useMemo } from "react"
import { dateFilter, dateRangeFilter, textFilter } from "../column-filter.component"
import TableDisplayer from "../table.component"
import { IBaseAdmin } from "../../interfaces/persons"
import { IActivityLog } from "../../interfaces/cubes"
import { mdiDatabaseEye } from "@mdi/js"
import Icon from "@mdi/react"
import ActivityLogModal from "./activity-log-modal.component"

interface IProps {
    logs: IActivityLog[]
}

function ActivityLogs(props: IProps) {
    const [selectedLog, setSelectedLog] = useState<IActivityLog>()
    const [showLogModal, setShowLogModal] = useState(false)

    const columns = useMemo(
        () => [
            {
                Header: "User",
                accessor: (data: any) => {
                    return `${(data.actor as IBaseAdmin) && (data.actor as IBaseAdmin).firstname} ${(data.actor as IBaseAdmin) && (data.actor as IBaseAdmin).lastname}`
                },
                Filter: textFilter,
            },
            {
                Header: "Actor role",
                accessor: "actorType",
                Filter: textFilter,
                isHidden: true,
            },
            {
                Header: "Description",
                accessor: "description",
                Filter: textFilter,
                isHidden: false,
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: (data: any): any => {
                    return (
                        <>
                            <span className={`action ${data.value}`}>{`${data.value}`}</span>
                            {data?.row?.original?.data ? (
                                <span
                                    onClick={() => {
                                        setSelectedLog(data?.row?.original)
                                        setShowLogModal(true)
                                    }}>
                                    <Icon className="data-icon" path={mdiDatabaseEye} size={1} />
                                </span>
                            ) : (
                                <></>
                            )}
                        </>
                    )
                },
                Filter: textFilter,
            },
            {
                Header: "Time",
                accessor: "createdAt",
                Cell: (data: any) => {
                    return `${moment(data.value).format("YYYY.MM.DD, h:mm")} (${moment(data.value).fromNow()})`
                },
                Filter: dateFilter,
                filter: dateRangeFilter,
            },
            {
                Header: "Entity type",
                accessor: "entityType",
                Filter: textFilter,
                isHidden: true,
            },
        ],
        []
    )
    return (
        <div className="activity-logs">
            <TableDisplayer columns={columns} data={props.logs} paginationOptions={{ pageSize: 5 }} />
            <ActivityLogModal show={showLogModal} onHide={() => setShowLogModal(false)} data={selectedLog?.data} />
        </div>
    )
}

export default ActivityLogs
