import OperatorDetailsHome from "../../components/operators/operator-details-home.component"
import OperatorUpdate from "../../components/operators/operator-update.component"
import { ISubPageRoute } from "../../interfaces/pages"

const operatorDetailsRoutes: ISubPageRoute[] = [
    {
        path: "/admin/operators/:id",
        name: "Details",
        component: OperatorDetailsHome,
    },
    {
        path: "/admin/operators/:id/update",
        name: "Edit",
        component: OperatorUpdate,
    },
]

export default operatorDetailsRoutes
