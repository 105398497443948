import CreateOperator from "../../components/operators/operator-create.component"
import OperatorsTable from "../../components/operators/operators-table.component"
import { ISubPageRoute } from "../../interfaces/pages"

const operatorsMainRoutes: ISubPageRoute[] = [
    {
        path: "/admin/operators/main",
        name: "Infos",
        component: OperatorsTable,
        props: {
            hiddenColumns: ["region", "email", "company", "taxNumber", "phone", "id", "registeredAt", "emailVerified"],
        },
    },
    {
        path: "/admin/operators/main/create",
        name: "Create Operator",
        component: CreateOperator,
    },
]

export default operatorsMainRoutes
