import AdminsTable from "../../components/superadmins/admin-table.component"
import CreateSuperadmin from "../../components/superadmins/create-superadmin.component"
import { ISubPageRoute } from "../../interfaces/pages"

const adminMainRoutes: ISubPageRoute[] = [
    {
        path: "/admin/superadmins/",
        name: "Infos",
        component: AdminsTable,
    },
    {
        path: "/admin/superadmins/create",
        name: "Create Admin",
        component: CreateSuperadmin,
    },
]

export default adminMainRoutes
