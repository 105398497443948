// Modules from the React eco-system
import React from "react"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// Own components
import RegionForm from "../forms/region.form"

// Axios
import axiosClient from "../../api/api"
import { AxiosError } from "axios"

// Interfaces, enums
import { IRegion } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"
import { useHistory } from "react-router-dom"

interface IProps {
    setRegions: Function
}

const FormInitialValues = {
    name: "",
    description: "",
    image: undefined,
    location: [47.18037912854737, 19.50450897216797],
}
/**
 * component for region creation
 */
function CreateRegion(props: IProps) {
    const history = useHistory()

    const formik = useFormik({
        initialValues: FormInitialValues,
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            description: Yup.string().required("Required"),
            image: Yup.mixed().required("Required"),
            location: Yup.array().min(2).max(2).required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                if (values.name && values.image) {
                    const formData = new FormData()
                    formData.append("type", "region")
                    formData.append("name", values.name)
                    formData.append("description", values.description)
                    formData.append("image", values.image)
                    formData.append("lat", values.location[0].toString())
                    formData.append("lng", values.location[1].toString())

                    const response = await axiosClient.post(`/region`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    props.setRegions((prev: IRegion[]) => [...prev, response.data])
                    toast.success("The region is created")
                    history.push(`/admin/regions/${response.data._id}`)
                    resetForm({
                        values: FormInitialValues,
                    })
                }
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })
    return (
        <Row>
            <Col>
                <div className="tile">
                    <RegionForm formik={formik} />
                </div>
            </Col>
        </Row>
    )
}

export default CreateRegion
