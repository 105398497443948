import React, { useState } from "react"
import { Marker, InfoWindow } from "@react-google-maps/api"
import { IMarkerData } from "../interfaces/pages"

interface IProps {
    markerData: IMarkerData
}

function GoogleMapMarker(props: IProps) {
    const [showInfo, setShowInfo] = useState(false)

    function handleDragEnd(e: google.maps.MapMouseEvent) {
        if (props.markerData.onDragEnd) {
            const lat = e.latLng?.lat()
            const lng = e.latLng?.lng()

            if (lat && lng) {
                props.markerData.onDragEnd(lat, lng)
            }
        }
    }

    return (
        <>
            {showInfo ? (
                <InfoWindow onCloseClick={() => setShowInfo(false)} position={props.markerData.position}>
                    {props.markerData.infoWindow}
                </InfoWindow>
            ) : (
                <></>
            )}
            <Marker
                onClick={(e) => setShowInfo(!showInfo)}
                draggable={props.markerData.draggable ? props.markerData.draggable : false}
                position={props.markerData.position}
                onDragEnd={(e) => handleDragEnd(e)}
            />
        </>
    )
}

export default GoogleMapMarker
