//  Modules from the React eco-system
import { useState, useEffect, useContext } from "react"

// Navigation
import { Route, Switch, useHistory } from "react-router-dom"
import suppliersMainRoutes from "../routes/mains/suppliers-main.route"
import SubPageNavbar from "../components/navigation/subpage-navbar.component"

// Axios
import axiosClient from "../api/api"

// Redux, store
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"
import { UserContext } from "../store/UserProvider"

// Own components
import Loader from "../components/loader.component"

// Interfaces, enums
import { ISupplier } from "../interfaces/persons"
import { ISubPageRoute } from "../interfaces/pages"
import { ERoles } from "../constants/enum"

function Suppliers() {
    //Logged in user
    const { user } = useContext(UserContext)

    const dispatch = useDispatch()
    const history = useHistory()

    const [suppliers, setSuppliers] = useState<ISupplier[]>([])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        dispatch(setPageName({ pageName: "Suppliers", pageUrl: "/admin/suppliers/main", subPageName: "" }))

        fetchSuppliers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchSuppliers() {
        setIsLoading(true)
        try {
            const response = await axiosClient.get("/supplier")
            setSuppliers(response.data)
        } catch (error) {
            console.log("Failed to fetch suppliers")
            history.push("/admin/404")
        }
        setIsLoading(false)
    }

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <SubPageNavbar
                        routes={suppliersMainRoutes.filter((route: ISubPageRoute) => {
                            return route.permission?.includes(user.role as ERoles)
                        })}
                    />

                    <Switch>
                        {suppliersMainRoutes
                            .filter((route: ISubPageRoute) => {
                                return route.permission?.includes(user.role as ERoles)
                            })
                            .map((route: ISubPageRoute, key: number) => {
                                return (
                                    <Route
                                        key={key}
                                        path={route.path}
                                        exact
                                        render={() => {
                                            return (
                                                <div>
                                                    <route.component suppliers={suppliers} setSuppliers={setSuppliers} {...route.props} />
                                                </div>
                                            )
                                        }}
                                    />
                                )
                            })}
                    </Switch>
                </>
            )}
        </div>
    )
}

export default Suppliers
