import { FormikProps } from "formik"
import { Form, Button, Row, Col } from "react-bootstrap"
import CustomGoogleMap from "../google-map.component"
interface IProps {
    formik: FormikProps<any>
}

function RegionForm({ formik }: IProps) {
    function getMarkerData() {
        return [
            {
                position: { lat: formik.values.location[0], lng: formik.values.location[1] },
                draggable: true,
                onDragEnd: (lat: number, lng: number) => formik.setFieldValue("location", [lat, lng]),
            },
        ]
    }

    return (
        <Form onSubmit={formik.handleSubmit} className="region-form">
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control onChange={formik.handleChange} name="name" onBlur={formik.handleBlur} value={formik.values.name} type="text" placeholder="Name of the region..." />
                {formik.touched.name && formik.errors.name ? <p className="input-error">{formik.errors.name}</p> : null}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCube">
                <Form.Label>Description</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={5}
                    onChange={formik.handleChange}
                    name="description"
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    type="text"
                    placeholder="Description of the cube..."
                />
                {formik.touched.description && formik.errors.description ? <p className="input-error">{formik.errors.description}</p> : null}
            </Form.Group>
            <Row>
                <Col xs={12} md={6}>
                    <Row className="coordinates-inputs">
                        <Col>
                            <Form.Group className="mb-3" controlId="formRegion">
                                <Form.Label>Lat.: </Form.Label>
                                <Form.Control
                                    onChange={(e) => {
                                        formik.setFieldValue("location", [Number(e.target.value), formik.values.location[1]])
                                    }}
                                    name="location"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.location[0] ? formik.values.location[0] : undefined}
                                    type="number"
                                    placeholder="Lat..."
                                    step="0.000000000000001"
                                    min="-90"
                                    max="90"
                                />
                                {formik.touched.location && formik.errors.location ? <p className="input-error">{formik.errors.location}</p> : null}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formRegion">
                                <Form.Label>Long.: </Form.Label>
                                <Form.Control
                                    onChange={(e) => {
                                        formik.setFieldValue("location", [formik.values.location[0], Number(e.target.value)])
                                    }}
                                    name="long"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.location[1] ? formik.values.location[1] : undefined}
                                    type="number"
                                    placeholder="Long..."
                                    step="0.000000000000001"
                                    min="-180"
                                    max="180"
                                />
                                {formik.touched.location && formik.errors.location ? <p className="input-error">{formik.errors.location}</p> : null}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CustomGoogleMap
                                onClick={(lat: number, lng: number) => formik.setFieldValue("location", [lat, lng])}
                                zoom={7}
                                center={formik.values.location[0] ? { lat: formik.values.location[0], lng: formik.values.location[1] } : { lat: 47, lng: 17 }}
                                markerDatas={getMarkerData()}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} md={6}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formCategoryImage">
                                <Form.Label>Image:</Form.Label>
                                <Form.Control
                                    onChange={(evt) => {
                                        formik.setFieldValue("image", (evt.target as any).files[0])
                                    }}
                                    name="image"
                                    type="file"
                                />
                                {formik.touched.image && formik.errors.image ? <div className="input-error">{formik.errors.image}</div> : null}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>{formik.values.image && <img className="preview-image" src={URL.createObjectURL(formik.values.image)} alt="uploaded" />}</Col>
                    </Row>
                </Col>
            </Row>

            <Button className="orange" type="submit">
                Submit
            </Button>
        </Form>
    )
}

export default RegionForm
