//  Modules from the React eco-system
import React, { useState, useContext } from "react"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// Own components
import PhotoUploader from "./profil-image-uploader.component"

// Redux, store
import { UserContext } from "../store/UserProvider"

// Axios
import { AxiosError } from "axios"
import axiosClient from "../api/api"

// Interfaces, enums
import { ERoles } from "../constants/enum"

// Styles, bootstrap, icons
import { Col, Row, Form, Button } from "react-bootstrap"

function ProfileUpdate() {
    //Logged in user
    const { user, updateUser } = useContext(UserContext)

    const [showImageUploader, setShowImageUploader] = useState(false)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            phone: user.phone,
        },
        validationSchema: Yup.object().shape({
            firstname: Yup.string(),
            lastname: Yup.string(),
            email: Yup.string().email("Email is incorrect"),
        }),
        onSubmit: async (values, actions) => {
            try {
                if (user) {
                    const updateData = {
                        ...(values.firstname && values.firstname !== user.firstname && { firstname: values.firstname }),
                        ...(values.lastname && values.lastname !== user.lastname && { lastname: values.lastname }),
                        ...(values.email && values.email !== user.email && { email: values.email }),
                        ...(values.phone && values.phone !== user.phone && { phone: values.phone }),
                    }
                    if (Object.keys(updateData).length) {
                        await axiosClient.patch(`/${user.role.toLowerCase()}/${user._id}`, updateData)
                        toast.success("The profile is updated")
                        updateUser({ ...user, ...updateData })
                    }
                }
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    return (
        <div>
            <div className="tile ">
                <Row>
                    <Col>
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group className="mb-3" controlId="formProfile">
                                <Form.Label>Firstname:</Form.Label>
                                <Form.Control onChange={formik.handleChange} name="firstname" onBlur={formik.handleBlur} value={formik.values.firstname} type="text" placeholder="Firstname..." />
                                {formik.touched.firstname && formik.errors.firstname ? <p className="input-error">{formik.errors.firstname}</p> : null}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formProfile">
                                <Form.Label>Lastname:</Form.Label>
                                <Form.Control onChange={formik.handleChange} name="lastname" onBlur={formik.handleBlur} value={formik.values.lastname} type="text" placeholder="Lastname..." />
                                {formik.touched.lastname && formik.errors.lastname ? <p className="input-error">{formik.errors.lastname}</p> : null}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formProfile">
                                <Form.Label>Email:</Form.Label>
                                <Form.Control onChange={formik.handleChange} name="email" onBlur={formik.handleBlur} value={formik.values.email} type="email" placeholder="Email..." />
                                {formik.touched.email && formik.errors.email ? <p className="input-error">{formik.errors.email}</p> : null}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formProfile">
                                <Form.Label>Phone:</Form.Label>
                                <Form.Control onChange={formik.handleChange} name="phone" onBlur={formik.handleBlur} value={formik.values.phone} type="text" placeholder="Telefonszám..." />
                                {formik.touched.phone && formik.errors.phone ? <p className="input-error">{formik.errors.phone}</p> : null}
                            </Form.Group>

                            <Button className="orange" type="submit">
                                Update
                            </Button>
                        </Form>
                    </Col>
                </Row>

                <Button onClick={() => setShowImageUploader(true)} className="orange">
                    Upload image
                </Button>

                <PhotoUploader
                    show={showImageUploader}
                    handleClose={() => setShowImageUploader(false)}
                    setPerson={(imageUrl: string) => updateUser({ ...user, image: imageUrl })}
                    personId={user._id}
                    role={user.role || ERoles.SUPERADMIN}
                />
            </div>
        </div>
    )
}

export default ProfileUpdate
