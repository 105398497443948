//  Modules from the React eco-system
import React, { useState, useEffect } from "react"

// Axios
import axiosClient from "../../api/api"

// Own components
import AdminInfo from "../admin-info.component"
import ActivityLogs from "../dashboard/activity-logs.component"

// Interfaces, enums
import { IOperator } from "../../interfaces/persons"
import { IActivityLog } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"

interface IProps {
    operator: IOperator
}
function OperatorDetailsHome(props: IProps) {
    const [logs, setLogs] = useState<IActivityLog[]>([])

    useEffect(() => {
        fetchLogs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchLogs() {
        try {
            const response = await axiosClient.get(`/log?actor=${props.operator._id}`)
            setLogs(response.data)
        } catch (error) {
            console.log("Failed to fetch logs")
        }
    }

    return (
        <>
            <Row>
                <Col md={4}>
                    <AdminInfo admin={props.operator} />
                </Col>
                <Col md={8}>
                    <div className="table-tile">
                        <ActivityLogs logs={logs} />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default OperatorDetailsHome
